import '../styles/index.scss';

$(function() {
  /**
   * Smooth scrolling to page anchor on click
   **/
  $("a[href*='#']:not([href='#'])").click(function () {
    if (
      location.hostname == this.hostname
      && this.pathname.replace(/^\//, "") == location.pathname.replace(/^\//, "")
    ) {
      var anchor = $(this.hash);
      anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) + "]");
      if (anchor.length) {
        $("html, body").animate({scrollTop: anchor.offset().top}, 500);
      }
    }
  });

  // get the form elements defined in your form HTML above
  var form = document.getElementById("contact-form");
  var button = document.getElementById("submit");
  var status = document.getElementById("my-form-status");

  // Success and Error functions for after the form is submitted
  function success() {
    form.reset();
    $('#my-form-status').addClass('good');
    button.style = "display: none ";
    status.innerHTML = "Coordonnées envoyées, merci !";
  }

  function error() {
    status.innerHTML = "Oops! Il y a eu un problème durant l'envoie.";
  }

  // handle the form submission event
  form.addEventListener("submit", function(ev) {
    ev.preventDefault();
    var data = new FormData(form);
    ajax(form.method, form.action, data, success, error);
  });
});

function ajax(method, url, data, success, error) {
  var xhr = new XMLHttpRequest();
  xhr.open(method, url);
  xhr.setRequestHeader("Accept", "application/json");
  xhr.onreadystatechange = function() {
    if (xhr.readyState !== XMLHttpRequest.DONE) return;
    if (xhr.status === 200) {
      success(xhr.response, xhr.responseType);
    } else {
      error(xhr.status, xhr.response, xhr.responseType);
    }
  };
  xhr.send(data);
}
